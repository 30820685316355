import React, { useState, useContext, useEffect } from 'react'
import Header from '../components/Header';
import { Routes, Route } from "react-router-dom";
import Footer from '../components/Footer/index.jsx';
import ProductsEnquiry from '../components/ProductsEnquiry';
import Category from '../pages/category';
import ProductScreen from '../pages/products';
import { GlobalContext } from '../contextAPI/globalState'
import CartMobileView from '../components/CartMobileView';

const Layout = () => {
    const [visible, setVisible] = useState(false)
    const [count, setCount] = useState(0)
    const { state } = useContext(GlobalContext);
    useEffect(() => {
        // console.log(state.enquiryProductList);
        setCount(state?.enquiryProductList?.length)
    }, [state?.enquiryProductList])
    return (
        <React.Fragment>
            <Header />
            <div className='row g-0'>
                <div className='col-12 col-lg-9 col-xl-10'  >
                    <Routes>
                        <Route path='/' element={<Category />} />
                        <Route exact path='/products' element={<ProductScreen />} />
                    </Routes>
                    <Footer />
                </div>
                <div className='col-0 col-lg-3 col-xl-2 d-none d-lg-flex'>
                    <ProductsEnquiry />
                </div>
                <div className='col-12 col-md-0 d-flex d-lg-none'>
                    <div onClick={() => setVisible(true)} className="floating-btn">
                        <span>{count}</span>
                        <i className="my-float fa fa-cart-plus"></i>
                    </div>
                    <CartMobileView visible={visible} dismissModal={() => setVisible(false)} />
                    {/* <ProductsEnquiry /> */}
                </div>
            </div>

        </React.Fragment>
    )
}

export default Layout;
