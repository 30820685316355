import React, { useState, useEffect, useContext } from "react";
import logo from '../../assets/logo.png';
import { Link } from "react-router-dom";
import { actions } from "../../contextAPI/globalAction";
import { useLocation } from 'react-router-dom';
import { GlobalContext } from "../../contextAPI/globalState";

export const Header = () => {
    const location = useLocation();
    const [searchValue, setSearchValue] = useState()
    const [isSearchShow, setSearchShow] = useState(false)
    const { dispatch, state } = useContext(GlobalContext)

    const onSearchHandler = (e) => {
        e.preventDefault();
        actions.getSearchProduct(searchValue, state.catId)
            .then(res => dispatch(actions.searchProducts(res)))
        // setSearchValue("")
    }

    useEffect(() => {
        if (location.pathname === "/products") {
            setSearchShow(true)
        } else {
            setSearchShow(false)
        }
    }, [location?.pathname])
    return <>
        <section className="footer d-flex d-lg-none" style={{ backgroundColor: '#b01116' }}>
            <div className="container-fluid container-md">
                <div className="row g-0 subftr-one d-flex align-items-center justify-content-between ">
                    <div className='col-6' ><p><i className='fa fa-envelope' /> <a href='mailto:info@foton-jw.com.pk'>info@foton-jw.com.pk</a></p></div>
                    <div className='col-6 d-flex justify-content-end' ><p><i className='fa fa-phone' /> <a href='tel:(042) 111-145-263'>(042) 111-145-263</a></p></div>

                </div>
            </div>
        </section>
        <div className="header">
            <nav className="navbar navbar-expand-lg navbar-light bg-white p-0">
                <Link className="navbar-brand" to="" >
                    <img src={logo} alt="logo" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse custom-nav" id="navbarTogglerDemo03">
                    <div className='row g-0 w-100'>
                        {isSearchShow && <div className={`col-12 ${isSearchShow ? 'col-md-8' : ""}`} >
                            <form onSubmit={onSearchHandler} >
                                <div className="input-group ">
                                    {/* <div className="dropdown">
                                    <button type="button" className="btn btn-primary dropdown-toggle border-0" data-bs-toggle="dropdown">
                                        All Filter
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Link 1</a></li>
                                        <li><a className="dropdown-item" href="#">Link 2</a></li>
                                        <li><a className="dropdown-item" href="#">Link 3</a></li>
                                    </ul>
                                </div> */}
                                    <input type="text" className="form-control" placeholder="Search" aria-label="Search"  value={searchValue} onChange={(e) => setSearchValue(e.target.value)} aria-describedby="button-addon2" />
                                    <button className="btn search-btn" type="submit" id="button-addon2"  ><i className="fa fa-search" /></button>
                                    {/* <input type="text" className="form-control" placeholder="Search" /> */}
                                </div>
                            </form>

                        </div>}
                        <div className={`col-12 ${isSearchShow ? 'col-md-4' : ''} mt-2 mt-md-0`}>
                            <div className='d-flex justify-content-center justify-content-md-end'>
                                <ul className="socail-icons">
                                    <li>
                                        <a target="_blank" href="https://www.facebook.com/JWForland/"><i className="fa fa-facebook-f"></i></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.instagram.com/jwforland/?igshid=1inskkddhr694x/"><i className="fa fa-instagram"></i></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.linkedin.com/company/jwforland/"><i className="fa fa-linkedin"></i></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://wa.me/+923144445263"><i className="fa fa-whatsapp"></i></a>
                                    </li>

                                </ul>
                            </div>

                        </div>

                    </div>
                </div>
            </nav>
        </div>
    </>
}





export default Header;