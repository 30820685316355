import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css'
import './scss/main.scss'
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import Layout from './layout';
import { GlobalProvider } from './contextAPI/globalState'

function App() {
  return (
    <GlobalProvider >
      <Router>
        <Helmet>
          <link href="./assets/favicon.png" rel="icon" />
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" ></script>
        </Helmet>

        <Layout />
      </Router>
    </GlobalProvider>
  );
}

export default App;
