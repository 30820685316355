export const FormRequiredMassages = {
    name:"Name is required",
    phone: 'Phone no. is required',
    email: 'Email is required',
    address: 'Address is required',
    preferred: 'Preffence is required',
    invalidEmail: 'Invalid email address',
}

export const Label = {
    password: 'Confirm Password'
}

export const Regex = {
    password: /^(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/,
    messageText: "Password must contain atleast one letter, one numeric character and one special character"
}

export const PasswordLength = {
    minLen: 8,
    validityText: 'Password is too short - should be 8 chars minimum.'
}