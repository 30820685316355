import React, { useContext, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { FormRequiredMassages } from "../../constants";
import { GlobalContext } from '../../contextAPI/globalState'
import { actions } from '../../contextAPI/globalAction';
import { AlertBox } from '../toast';
// import JazzCash from '../../assets/jazzcash.png'
// import EasyPasia from '../../assets/easypaisa.png'
// import Payoneer from '../../assets/payoneer.png'
import { ImageBaseUrl } from '../../contextAPI/config';

const schema = yup.object({
    name: yup.string().required(FormRequiredMassages.name),
    email: yup.string().email(FormRequiredMassages.invalidEmail).required(FormRequiredMassages.email),
    phone: yup.string().required(FormRequiredMassages.phone),
    address: yup.string().required(FormRequiredMassages.address),
    prefferred: yup.string().required().nullable(),

}).required();

// const PaymentMethod = [
//     { name: "JazzCash", image: JazzCash },
//     { name: "EasyPasia", image: EasyPasia, },
//     { name: "Payoneer", image: Payoneer, }
// ]

const Contactform = ({ paymentData, selectedMethod, setSelectedMethod, setShowPaymentModal }) => {
    const { dispatch, state } = useContext(GlobalContext);
    const { enquiryProductList } = state;
    const [paymentMethod, setPaymentMethod] = useState([]);
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const onHandleSubmit = (data) => {
        try {
            if (Array.isArray(enquiryProductList) && enquiryProductList.length > 0) {
                let products = enquiryProductList.map(val => { return { id: val.id, qty: val.qty } })
                let obj = {
                    customer_name: data?.name || "",
                    customer_email: data?.email,
                    customer_phone: data?.phone,
                    customer_preference: data?.prefferred,
                    address: data?.address,
                    payment_gateway: selectedMethod?.id,
                    products
                }
                // console.log(obj);
                actions.postSaleEnquired(obj).then(data => {
                    console.log({data});

                    if(data?.success){
                        dispatch(actions.productsEnquiry([]))
                        reset()
                        AlertBox(data?.message, 'Success', 'success')
                    }
                    // else{
                    //     AlertBox(data?.message, 'Something went wrong.', 'error')
                    // }
                })
            } else {
                AlertBox("Please, add atleast one product into cart before submitting the form. Thanks!", 'Alert!!', false)
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    const onSelectMethod = (methodType) => {
        setSelectedMethod(methodType)
        setShowPaymentModal(true)
    }
    useEffect(() => {
        setPaymentMethod(paymentData?.data || [])
    }, [paymentData])
    return (
        <section className="form-wrap contactform-wrapper">
            <div className="container">
                <div className="row g-0 align-items-center justify-content-center ">
                    <div className="col-md-12">
                        <form className="row g-0 formslayouts" onSubmit={handleSubmit(onHandleSubmit)}>
                            <div className="col-md-12">
                                <div className="forms_wrapper">
                                    <input type="text" className="form-control form__field" {...register("name")} placeholder="Name" />
                                    <label htmlFor="inputEmail4" className="form-label form__labels">Name</label>
                                    {errors.name && <p>{errors.name?.message}</p>}

                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="forms_wrapper">
                                    <input type="email" className="form-control form__field" {...register("email")} placeholder="Email" />
                                    <label htmlFor="inputEmail4" className="form-label form__labels">Email</label>
                                    {errors.email && <p>{errors.email?.message}</p>}

                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="forms_wrapper">
                                    <input type="tel" className="form-control form__field" {...register("phone")} placeholder="Phone No." />
                                    <label htmlFor="phone" className="form-label form__labels">Phone</label>
                                    {errors.phone && <p>{errors.phone?.message}</p>}

                                </div>
                            </div>

                            <div className="col-12">
                                <div className="forms_wrapper ">
                                    <div className='row g-0'>
                                        <div className='col-md-7'>
                                            <div className='d-flex justify-content-between align-items-center '>
                                                <div className="preffered-text">Preferred:</div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio"  {...register("prefferred")} value="Email" />
                                                    <i className='fa fa-envelope' />
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" {...register("prefferred")} value="Call" />
                                                    <i className='fa fa-phone' />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {errors.prefferred && <p>Select any one of the above to get in touch</p>}
                                </div>
                            </div>
                            <div className='p-title'>
                                <h1>Payment Method</h1>
                                <hr />
                                {/* <Contactform /> */}
                                <div className='row g-0 flex-nowrap payment-method'>
                                    {paymentMethod.map((val) => 
                                        <div className={`payment-method-item ${selectedMethod.id === val.id ? "active" : ''}`} onClick={() => onSelectMethod(val)}>
                                            <img  src={ImageBaseUrl + val.image} />
                                        
                                    </div>)}

                                    {/* <div className='col-4'>
                                        <div className='payment-method-item' onClick={() => onSelectMethod("EasyPaise")}>
                                            <img src={EasyPaise} />
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className='payment-method-item' onClick={() => onSelectMethod("Payoneer")}>
                                            <img src={Payoneer} />
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="forms_wrapper">
                                    <input type="text" className="form-control form__field" {...register("address")} placeholder="Address" />
                                    <label htmlFor="address" className="form-label form__labels">Address</label>
                                    {errors.address && <p>{errors.address?.message}</p>}

                                </div>
                            </div>
                            <div className="col-md-12 form-footer">
                                <div className='row g-6 justify-content-between'>
                                    <div className="col-6">
                                        <button type="button" onClick={() => reset()} className="btn btn-cancel form-btn-wrap">Clear</button>
                                    </div>
                                    <div className="col-6">
                                        <button disabled={selectedMethod ? false : true} type="submit" className="btn btn-ok form-btn-wrap">Inquire Now</button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>


                </div>
            </div>
        </section>
    )
}

export default Contactform
