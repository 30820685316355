import React from 'react'
import JazzCash from '../../assets/jazzcash.png'
import EasyPasia from '../../assets/easypaisa.png'
import Payoneer from '../../assets/payoneer.png'
import { ImageBaseUrl } from '../../contextAPI/config'


const PaymentMathod = ({paymentData, selectedMethod, onclose, isShowPaymentModal}) => {

    const tempMethod = paymentData?.data?.find((payment) => payment.id === selectedMethod.id )
    
    const paymentImage = ImageBaseUrl + tempMethod?.image;

    return (
        <div className='payment-method'>
            <div class={`modal fade ${isShowPaymentModal ? 'show d-flex' : ''} `} id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalToggleLabel">Payment Method</h5>
                            <img height={50} src={paymentImage} />
                            <button type="button" class="btn-close"  onClick={onclose}></button>
                        </div>
                        <div class="modal-body">
                            {tempMethod?.bank_name && <div className='d-flex justify-content-between align-items-center'><p className='acc-text d-flex justify-content-between align-items-center'><span>Name</span><span>{tempMethod?.bank_name || "XXXX-XXX-XXXX"}</span></p></div>}
                            {tempMethod?.title && <div className='d-flex justify-content-between align-items-center'><p className='acc-text d-flex justify-content-between align-items-center'><span>Account Title</span><span>{tempMethod?.title || "XXXX-XXX-XXXX"}</span></p></div>}
                            {tempMethod?.account_no &&<div className='d-flex justify-content-between align-items-center'><p className='acc-text d-flex justify-content-between align-items-center'><span>Account Number</span><span>{tempMethod?.account_no || "XXXX-XXX-XXXX"}</span></p></div>}
                            {tempMethod?.IBAN  && <div className='d-flex justify-content-between align-items-center'><p className='acc-text d-flex justify-content-between align-items-center'><span>IBAN</span><span>{tempMethod?.IBAN || "XXXX-XXX-XXXX"}</span></p></div>}
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentMathod
