import React from 'react'
import { Link } from 'react-router-dom';
import { ImageBaseUrl } from '../contextAPI/config';
const CategoryCard = ({ data }) => {
    const { id, name, image } = data;
    let tempImg = ImageBaseUrl + image;
    
    // console.log({data, tempImg});
    return (
        <Link to={{ pathname: '/products', search: id.toString() }} className='text-decoration-none' >
            <div key={id} className="card cat-card" >
                <img src={tempImg} className="card-img-top" alt="..." />
                <div className="card-body">
                    <p className="card-text">{name}</p>
                </div>
            </div>
        </Link>
    )
}

export default CategoryCard
