import React, { useContext } from 'react'
import { GlobalContext } from '../contextAPI/globalState'
import { actions } from '../contextAPI/globalAction';
import { ImageBaseUrl } from '../contextAPI/config';
import { Toast } from './toast';

const ProductsCard = ({ data, CatTitle }) => {
    const { id, name, image, price } = data;
    const { dispatch, state } = useContext(GlobalContext);
    const { enquiryProductList } = state;
    // let temp = lazy(() => import(img));
    let tempImg = ImageBaseUrl + image;


    const AddToEnquiryProduct = (data) => {
        let tempList = [];
        // console.log(isAlreadyExist);
        let isAlreadyExist = enquiryProductList.filter((val) => val.id === data.id)
        if (Array.isArray(isAlreadyExist) && isAlreadyExist.length > 0) {
            let quantity = isAlreadyExist[0].qty;
            quantity++;
            isAlreadyExist[0].qty = quantity;
            tempList = [...enquiryProductList];
            dispatch(actions.productsEnquiry(tempList))
            Toast('Product added to the cart!', "success")
        } else {
            // console.log({ elseChala: data });
            let newdata = {
                ...data,
                qty: 1,
                catTitle: CatTitle
            }
            tempList = [...enquiryProductList, newdata];
            dispatch(actions.productsEnquiry(tempList))
            Toast('Product added to the cart!', "success")
        }
    }
    return (
        <div key={id} className="card prod-card" >
            <div className='d-flex justify-content-center align-items-center'>
                <div className='prod-img'>
                    <img src={tempImg} className="prod-img-top" alt="..." width="100" height="100"/>
                </div>
            </div>
            <div className="card-body d-flex justify-content-between align-items-center">
                <div className='product-card '>
                    <p className="card-text line-clamp line-2">{name}</p>
                    {/* <p className='card-model'>Rs.{price || " NA"}</p> */}
                </div>
                <i className='fa fa-plus' onClick={() => AddToEnquiryProduct(data)} />

            </div>
        </div>
    )
}

export default ProductsCard
