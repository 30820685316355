import React, { useEffect, useState } from 'react'
import CategoryCard from '../components/CategoryCard'
import { actions } from '../contextAPI/globalAction';
import EmptyBox from '../components/EmptyBox';


// console.log({CategoryData});
const Category = () => {
    const [categoryList, setCategoryList] = useState(false);
    useEffect(() => {
        actions.getCotegories().then(res => setCategoryList(res?.data))
    },[])
    return (
        <section className='categories-section'>
            <div className="card">
                <h5 className="card-header">Categories</h5>
                <div className="card-body card-product-body p-4 d-flex justify-content-center justify-content-md-center align-items-center align-content-start flex-wrap">
                {(Array.isArray(categoryList) && categoryList.length > 0) ?
                   Array.from(categoryList, (val) => <CategoryCard key={val.id} data={val} />)
                   :
                   <EmptyBox text={"Category is not found."} />
                }
                </div>
            </div>
        </section>
    )
}

export default Category
