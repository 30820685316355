import React, { useContext, useEffect, useState } from 'react'
import ProductsCard from '../components/ProductsCard';
// import ProductData from "../dummydata/products.json";
import { actions } from '../contextAPI/globalAction';

import { useNavigate, useLocation } from 'react-router-dom';
import { GlobalContext } from '../contextAPI/globalState';
import { Toast } from '../components/toast';
import EmptyBox from '../components/EmptyBox';




const ProductScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [productsList, setProductsList] = useState([]);
    const [categoryTitle, setCategoryTitle] = useState(false);
    const { dispatch ,state } = useContext(GlobalContext);
    const handleBack = () => {
        navigate('/');
    }
    useEffect(() => {
        actions.getCotegoriesById(location?.search.replace('?', "")).then(res => { setProductsList(res?.data?.product); setCategoryTitle(res?.data?.name) })
        dispatch(actions.setCatgoryId(location?.search.replace('?', "")))
    }, [location?.search])
    useEffect(() => {
        setProductsList(state?.searchProductsList?.data)
    }, [state?.searchProductsList?.data])
    // console.log({ location });
    return (
        <section className='products-section'>

            <div className="card">
                <h5 className="card-header d-flex justify-content-between align-items-center">
                    <p className='m-0'>{categoryTitle}</p>
                    <div className='back-btn d-flex align-items-center' onClick={handleBack}><i className='fa fa-arrow-left' /><span>Back</span></div>
                </h5>
                <div className="card-body card-product-body p-4 d-flex justify-content-center justify-content-md-center  align-items-center align-content-start flex-wrap">
                    {(Array.isArray(productsList) && productsList.length > 0) ?
                        Array.from(productsList, (val) => <ProductsCard key={val.id} data={val} CatTitle={categoryTitle} />)
                        :
                        <EmptyBox text={"Product is not found."} />
                    }

                </div>
            </div>
        </section>
    )
}

export default ProductScreen
