import React from 'react'
import ProductsEnquiry from './ProductsEnquiry'

const CartMobileView = ({visible, dismissModal}) => {
    return (
        <div className={`modal ${visible ? 'd-block' : "d-none"}`}>
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content mob-view-map-modal">
                <div className="modal-header border-0">
                    {/* <h5 className="modal-title">CART</h5> */}
                    <button type="button" className="btn-close" onClick={dismissModal}></button>
                </div>
                <div className="modal-body p-0">
                    <ProductsEnquiry />
                </div>
            </div>
        </div>
    </div>
    )
}

export default CartMobileView
