import React from 'react'

const Footer = ({ }) => {
        return (
                <section className="footer">
                        <div className="container-fluid container-md">
                                <div className="row g-0 ">
                                        <div className="col-12 d-none d-lg-flex col-lg-8 subftr-one align-items-center justify-content-between">
                                                <div className="row g-0 w-100">
                                                        <div className='col-12 col-sm-6   d-flex  justify-content-center' ><p><i className='fa fa-envelope' /> <a href='mailto:info@foton-jw.com.pk'>info@foton-jw.com.pk</a></p></div>
                                                        <div className='col-12 col-sm-6 d-flex  justify-content-center' ><p><i className='fa fa-phone' /> <a href='tel:(042) 111-145-263'>(042) 111-145-263</a></p></div>

                                                </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-4 subftr-sec d-flex align-items-center justify-content-center justify-content-md-center">
                                                <p>Copyright {(new Date()).getFullYear()} © <span> JW Forland</span> | All Rights Reserved</p>
                                        </div>
                                </div>
                        </div>

                </section>
        )
}


export default Footer