import React, { useContext, useState, useEffect } from 'react'
import Contactform from './ContactForm';
import ProductsItem from './ProductsItem';
import { GlobalContext } from '../../contextAPI/globalState'
import EmptyBox from '../EmptyBox';
import { actions } from '../../contextAPI/globalAction';
import { Toast } from '../toast';
import PaymentMathod from '../../components/ProductsEnquiry/PaymentMathod';


const ProductsEnquiry = () => {
    const { dispatch, state } = useContext(GlobalContext);
    const { enquiryProductList } = state;
    const [selectedMethod, setSelectedMethod] = useState('')
    const [paymentData, setPaymentData] = useState([])
    // const [totalPrice, setTotalPrice] = useState(0)
    const [isShowPaymentModal, setShowPaymentModal] = useState(false)

    // console.log({ enquiryProductList });

    const onIncreaseHandler = (data) => {
        let tempList = [];
        let isAlreadyExist = enquiryProductList.filter((val) => val.id === data.id)
        if (Array.isArray(isAlreadyExist) && isAlreadyExist.length > 0) {
            let quantity = isAlreadyExist[0].qty;
            quantity++;
            isAlreadyExist[0].qty = quantity;
            tempList = [...enquiryProductList];
            dispatch(actions.productsEnquiry(tempList))
        }
        // console.log("onIncreaseHandler",data);
    }
    const onDecreseHandler = (data) => {
        let tempList = [];
        let isAlreadyExist = enquiryProductList.filter((val) => val.id === data.id)
        if (Array.isArray(isAlreadyExist) && isAlreadyExist.length > 0) {
            let quantity = isAlreadyExist[0].qty;
            if (quantity > 1) {
                quantity--;
                isAlreadyExist[0].qty = quantity;
                tempList = [...enquiryProductList];
                dispatch(actions.productsEnquiry(tempList))
            }
        }

    }
    const onRemoveHandler = (data) => {
        var tempList = [...enquiryProductList]
        var index = tempList.findIndex((val) => val.id === data.id)
        if (index !== -1) {
            tempList.splice(index, 1)
            dispatch(actions.productsEnquiry(tempList))
            Toast('Remove product from the cart!', "error")
        }

    }
    
    // useEffect(() => {
    //     let totalamt =  enquiryProductList.reduce((accumulator, { price, qty }) => {
    //         return accumulator + (qty * price)
    //       }, 0)
    //    setTotalPrice(totalamt)
       
    // }, [enquiryProductList])
    useEffect(async () => {
        let res = await actions.getPaymentMethod()
        if(res?.success){
            setPaymentData(res)
        }
    }, [])

    return (
        <section className='product-section d-flex flex-column justify-content-between'>
            <PaymentMathod paymentData={paymentData} isShowPaymentModal={isShowPaymentModal} selectedMethod={selectedMethod} onclose={() => setShowPaymentModal(false)} />

            <div className='products'>
                <div className='p-title'>
                    <h1>Products Inquiry</h1>
                    <hr />
                    <div className='product-cart'>

                        {Array.isArray(enquiryProductList) && enquiryProductList.length > 0
                            ?
                            enquiryProductList.map(val => <ProductsItem key={val.id} onRemove={onRemoveHandler} onDecrease={onDecreseHandler} onIncrease={onIncreaseHandler} data={val} />)
                            :
                            <EmptyBox text={"Empty Cart"} />
                        }
                    </div>
                    {/* <div><p className='total-amm d-flex justify-content-between align-items-center'><span>Total</span><span>Rs.{totalPrice}/-</span></p></div> */}
                </div>

            </div>
            <div className='contact-form'>
                <div className='p-title'>
                    <h1>Contact Information</h1>
                    <hr />
                    <Contactform paymentData={paymentData} setSelectedMethod={setSelectedMethod} selectedMethod={selectedMethod} setShowPaymentModal={setShowPaymentModal} />
                </div>

            </div>
        </section>
    )
}

export default ProductsEnquiry;
