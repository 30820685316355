import React, { useState, useContext, useEffect } from 'react'

const ProductsItem = ({ data, onIncrease, onDecrease, onRemove }) => {

    const [count, setCount] = useState(0)
    useEffect(() => {
        setCount(data.qty)
    }, [data.qty])
    return (
        <div key={data.id} className="row cart-item d-flex justify-content-between align-items-center">
            <div className='col-6 p-0' style={{ paddingRight: 1 }}>
                <p className=' item-text'>
                    {data.name}
                    <span> ({data.catTitle})</span>
                </p>
            </div>
            <div className='col-5 p-0'>
                <p className='actions  d-flex justify-content-end'>
                    <i className='fa fa-minus' onClick={() => onDecrease(data)} />
                    <span>{count}</span>
                    <i className='fa fa-plus' onClick={() => onIncrease(data)} />
                    <i className='fa fa-close cart-remove' onClick={() => onRemove(data)} />
                </p>
            </div>

        </div>
    )
}

export default ProductsItem
